import React, { useEffect, useState, FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { CLICK_TO_PAY_CONFIG } from "../../../../constants/app";
import HmacMD5 from 'crypto-js/hmac-md5';
import { getCookieByName } from "../../../../utils/helper";
import "./click2pay.scss";

export interface ICardRef {
  submit: () => any;
}

interface IClick2payProps {
  userSessionId: string;
  amount: number;
  click2pay: (data: any) => void;
}
const Click2pay: FC<IClick2payProps> = ({
  userSessionId,
  amount,
  click2pay,
  ...props
}) => {
  const [click2payUrl, setClick2payUrl] = useState("");
  const [eventListner, setEventListner] = useState<any>(null);

  const { userDetails, memberDetail } = useSelector((state: RootState) => ({
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
  }));

  useEffect(() => {
    const phone = userDetails?.phoneNumber
      ? userDetails?.phoneNumber
      : memberDetail &&
        memberDetail?.LoyaltyMember &&
        memberDetail?.LoyaltyMember?.MobilePhone
      ? (memberDetail?.LoyaltyMember?.MobilePhone).replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "")
      : "";
    setClick2payUrl(
      `${CLICK_TO_PAY_CONFIG.HOST_URL}/v3/clicktopay/${
        CLICK_TO_PAY_CONFIG.USER_NAME
      }/${userSessionId}/${
        CLICK_TO_PAY_CONFIG.CURRENCY
      }/${amount}/${HmacMD5(
        [userSessionId, amount, CLICK_TO_PAY_CONFIG.CURRENCY].join(":"),
        CLICK_TO_PAY_CONFIG.SHARED_SECRET
      ).toString()}?return_target=_self&iframe=true&tokenize_only=true&postmessage=true${
        userDetails?.email?'&email='+userDetails?.email:''
      }${phone?'&mobile='+phone:''}`
    );
    if (window) {
      (window as any).paymentHost = CLICK_TO_PAY_CONFIG.HOST_URL;
      const listner = (event: any) => {
        if (event.origin !== (window as any).paymentHost) {
          return;
        }
        if ("data" in event === false) {
          return;
        }
        if (event.data.subject === "fz.click_to_pay.tokenization.success") {
          const {
            data: {
              data: {
                gatewayResponse: {
                  token,
                  cardExpiry,
                  cardHolder,
                  cardNumber,
                  cardSubcategory,
                  cardType,
                },
              },
              channel,
            },
          } = event;
          click2pay({
            cvcnumber: "",
            expirymonth: cardExpiry.split('/').shift(),
            expiryyear: cardExpiry.split('/').pop(),
            cardExpiry: cardExpiry,
            cardHolder: cardHolder,
            name: cardHolder,
            number: cardNumber,
            cardNumber,
            cardSubcategory,
            cardType,
            expiryDate: cardExpiry,
            cardHolderName: cardHolder,
            cvv: "",
            paymentMethodType: 'click2pay',
            token
          });
        } else if (event.data.subject === "fzi.tc_res") {
          const {
            data: {
              data: {
                token,
                card_expiry,
                card_holder,
                card_number,
                card_subcategory,
                card_type,
              },
              channel,
            },
          } = event;
          click2pay({
            cvcnumber: "",
            expirymonth: card_expiry.split('/').shift(),
            expiryyear: card_expiry.split('/').pop(),
            cardExpiry: card_expiry,
            cardHolder: card_holder,
            name: card_holder,
            number: card_number,
            cardNumber:card_number,
            cardSubcategory: card_subcategory,
            cardType: "mastrcard",
            expiryDate: card_expiry,
            cardHolderName: card_holder,
            cvv: "",
            paymentMethodType: 'click2pay',
            token
          });
        }
        if (event.data.subject === "fz.click_to_pay.mrt") {
          const mrt = event?.data?.data?.mrt
          if (mrt) {
            var date = new Date();
            date.setTime(date.getTime() + (180 * 24 * 60 * 60 * 1000));
            const expires = "; expires=" + date.toUTCString();
            document.cookie = `mrt=${mrt}; path=/; secure; expires=${expires}`;
          }
        }
        if (event.data.subject === "fz.click_to_pay.initial_mrt_req") {
          const paynowIframe = document.querySelector('#click-to-pay-iframe') as HTMLIFrameElement | null;
          if (paynowIframe && paynowIframe.contentWindow) {
            const cookie = getCookieByName('mrt');
            if (cookie) {
              const message = {
                channel: 'click_to_pay',
                subject: 'fz.click_to_pay.initial_mrt_res',
                data: {
                  mrt: cookie
                },
              };
              const paymentHost = (window as any).paymentHost || "*"; // Ensure it's defined or use "*"              
              paynowIframe.contentWindow.postMessage(message, paymentHost);
            }
          } else {
            console.error("Iframe not found or contentWindow is null.");
          }
        }
      };
      window.addEventListener("message", listner);
      if (window) {
        const iframeBody = (document.getElementById('click-to-pay-iframe') as any)
        iframeBody.height = iframeBody.scrollHeight === 0?500:iframeBody.scrollHeight*3
      }
      return () => {
        window.removeEventListener("message", listner);
      };
    }
  }, []);

  return (
    <div className="click-to-pay">
      <iframe id="click-to-pay-iframe" src={click2payUrl}></iframe>
    </div>
  );
};

export default Click2pay;
